import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import FmsMasterProjDetails from './masterProjectDetails';
import { ModelSelect } from 'vue-search-select';
import OrganizationList from '../../../admin/organization';
export default {
  name: 'FmsMasterProject',
  components: {
    ModelSelect,
    FmsMasterProjDetails,
    OrganizationList
  },
  watch: {
    currentPage: function() {
      this.getMasterProjectList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMasterProjectList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      timeoutData: null,
      showSelectedProjectRecord: false,
      showValueSetModal: false,
      orgType: null,
      openOuModal: false,
      loader: false,
      payload: {},
      editMode: false,
      updateMode: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      legalEntity: {
        value: null,
        text: null
      },
      crmMasterProj: {
        value: null,
        text: null
      },
      fmsMasterProjName: '',
      fmsMasterProjCode: '',
      masterProjectData: [],
      masterProjectFields: [
        {
          key: 'org_name',
          label: 'Legal Entity'
        },
        {
          key: 'fms_master_prj_name',
          label: 'Master Project'
        },
        {
          key: 'fms_master_prj_code',
          label: 'Master Project Code'
        },
        {
          key: 'crm_master_prj_name',
          label: 'CRM Master Project'
        }
      ],
      defaultValue: {
        text: null,
        value: null
      },
      routeName: this.$router.currentRoute.name
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedProjectRecord = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getMasterProjectList',
            'fms-masterproject',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getMasterProjectList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        le_id: this.legalEntity.value,
        crm_master_prj_id: this.crmMasterProj.value,
        fms_master_prj_name: this.fmsMasterProjName,
        fms_master_prj_code: this.fmsMasterProjCode
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getMasterProjectList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.masterProjectData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRM_MASTER_PROJECT) {
        this.crmMasterProj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    updateList() {
      this.getMasterProjectList();
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsMasterProject') {
        this.showSelectedProjectRecord = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectMasterProjectList', rowData);
      }
    },
    clearFilters() {
      this.legalEntity = this.defaultValue;
      this.crmMasterProj = this.defaultValue;
      this.fmsMasterProjName = '';
      this.fmsMasterProjCode = '';
      this.masterProjectData = [];
      this.totalRows = null;
    },
    hideProjectRecord() {
      this.showSelectedProjectRecord = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.crmMasterProj.value) {
        this.crmMasterProj = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutData, this.timeoutEdit);
    this.unsubscribe();
  }
};
